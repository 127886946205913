<template>
  <div class="flex w-full h-screen justify-center items-center p-5 px-44 max-sm:px-5">
    <div class="w-full">
      <div class="mx-auto h-[500px] max-md:h-[400px] rounded-full  p-10 max-md:p-3  mb-10 max-md:mb-5">
        <img :src="!specker ? require('../assets/manAvatar.png') : require('../assets/manAvatar.gif')" alt="" class="mx-auto h-full object-contain !w-full">
      </div>
      <div v-if="!load" class="w-full flex items-center bg-gray-200 border border-gray-300 h-12 rounded-3xl pl-5">
        <input v-model="value" placeholder="Buraya Yazı Yazınız" class=" border-none  w-full bg-transparent outline-none h-full"
          @keypress.enter="tts" />
        <button variant="ghost" class="!hover-none bg-red-500 h-full px-6 text-white rounded-r-3xl" @click="tts">
          Gönder
        </button>
      </div>
      <div v-else class="h-12 text-center">
        Yükleniyor...
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Howl } from "howler";

export default {
  name: "asistan-data",
  data() {
    return {
      value: "",
      load: false,
      specker:false,
    }
  },
  methods: {
    async tts() {
      this.load = true;
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/audio/speech",
          {
            model: "tts-1",
            input: this.value,
            voice: "onyx",
            response_format: "mp3",
          },
          {
            headers: {
              Authorization: "Bearer sk-proj-UbsqXKhtAFvP68KtsDQlT3BlbkFJZjQEYC6WOG03ko7BFHjn",
            },
            responseType: "blob",
          }
        );

        this.value = "";
        const audioData = response.data;
        const blob = new Blob([audioData], { type: "audio/mp3" });
        let url = URL.createObjectURL(blob);

        this.sound = new Howl({
          src: [url],
          format: ["mp3"],
          autoplay: true,
          onplay: () => {
            this.specker = true;
            //this.$emit("startValue", true);
          },
          onend: () => {
            this.specker = false;
          //  this.$emit("endValue", true);
          },
        });
        this.sound.play();
        this.load = false;
      } catch (err) {
        this.load = false;
        alert(err)
      }
    }
  },
}
</script>