import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.config.productionTip = false


import commonMixins from './plugins/commonMixins'
Vue.mixin(commonMixins)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
